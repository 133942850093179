import React, { memo } from "react";

import { CreateButton } from "../../atoms/commons/CreateButton";
import { Title } from "../../atoms/commons/Title";
import SCIcon from "../../../images/stress-check-list.png";
import { Paginate } from "../../atoms/commons/Paginate";
import { StressCheckListTable } from "../../atoms/stressChecks/stressCheckListTable";
import { StressCheckType } from "../../../types/api/stressCheck";

export const StressCheckList = memo(
  (props: {
    stressCheckList: StressCheckType[];
    paginateObj: {
      handlePageChange: (data: { selected: number }) => void;
      offset: number;
      perPage: number;
    };
    companyID: string | undefined;
  }) => {
    const { paginateObj, stressCheckList, companyID } = props;
    const { offset, perPage, handlePageChange } = paginateObj;
    return (
      <>
        {stressCheckList.length !== 0 ? (
          <>
            <div className="flex justify-between min-h-147">
              <Title src={SCIcon}>ストレスチェック一覧</Title>
              <CreateButton
                path={`/merchants/detail/${companyID}/stress_check_list/create`}
                placementClassName="absolute top-[65px] right-[35px]"
              />
            </div>
            <div className="mt-5 justify-center flex flex-col w-full">
              <table className="py-5 text-md justify-center mx-auto w-full">
                <thead>
                  <tr className="border-b leading-none text-lightGreen-200">
                    <th className="text-left py-5 w-24">テスト名</th>
                    <th className="text-left py-5 w-24">受検期間</th>
                    <th className="text-left py-5 w-20"></th>
                  </tr>
                </thead>
                {stressCheckList
                  .slice(offset, offset + perPage)
                  .map((stressCheck) => (
                    <StressCheckListTable
                      key={stressCheck.id}
                      stressCheck={stressCheck}
                      companyID={companyID}
                    />
                  ))}
              </table>
            </div>
            <Paginate
              handlePageChange={handlePageChange}
              offset={offset}
              perPage={perPage}
              listLength={stressCheckList.length}
            />
          </>
        ) : (
          <>
            <div className="border-b pb-10">
              <Title src={SCIcon}>ストレスチェック一覧</Title>
              <CreateButton
                path={`/merchants/detail/${companyID}/stress_check_list/create`}
                placementClassName="absolute top-[65px] right-[35px]"
              />
            </div>
            <div className="flex flex-col justify-center items-center w-full h-screen static">
              <span className="font-bold text-3xl text-lightGreen-200 absolute top-1/2 right-1/5">
                ストレスチェックのデータは存在しません。
              </span>
            </div>
          </>
        )}
      </>
    );
  }
);
