import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { LoadingCheckProvider } from "./providers/LoadingCheckProvider";
import { LoginUserProvider } from "./providers/LoginUserProvider";
import { Router } from "./router/Router";

export const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <LoadingCheckProvider>
          <LoginUserProvider>
            <Router />
          </LoginUserProvider>
        </LoadingCheckProvider>
      </BrowserRouter>
    </div>
  );
};
