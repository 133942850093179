import React, { memo, FC } from "react";

import Logo from "../../../images/logo.png";
import { useAuth } from "../../../hooks/useAuth";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  PasswordChangeRequestType,
  ChangePasswordFormType,
} from "../../../types/api/user";
import { Link } from "react-router-dom";

export const PasswordChange: FC = memo(() => {
  const { updatePassword } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<ChangePasswordFormType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit: SubmitHandler<ChangePasswordFormType> = (data) => {
    const requestData: PasswordChangeRequestType = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    };
    updatePassword(requestData);
  };

  return (
    <div className="bg-lightGreen-100 flex flex-col justify-center items-center sm:py-12 min-h-screen w-full">
      <div className="flex flex-col space-y-16 items-center  px-[150px] pt-20 pb-28 bg-white shadow rounded-lg">
        <div className="flex justify-center items-center">
          <img src={Logo} alt="logo.png" width={300} height={200} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-5 items-center justify-end w-[460px]">
            <label className="text-lg font-w6 leading-none text-gray-700 w-full">
              現在のパスワード
            </label>
            <input
              className="px-3 py-2 mt-1 mb-5 text-sm w-full border rounded-lg border-gray-700"
              placeholder="現在のパスワード"
              type={"password"}
              value={watch("oldPassword")}
              {...register("oldPassword", {
                required: "この項目は必須です",
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,./:;<=>?@]{8,50}$/,
                  message:
                    "8文字以上50文字以内、大文字含む英数字で入力してください",
                },
              })}
            />
            {errors.oldPassword?.message && (
              <p className="text-red-600 ml-0 w-[460px]">
                {errors.oldPassword.message}
              </p>
            )}
            <label className="text-lg font-w6 leading-none text-gray-700 w-full">
              新しいパスワード
            </label>
            <input
              className="px-3 py-2 mt-1 mb-5 text-sm w-full border rounded-lg border-gray-700"
              type={"password"}
              value={watch("newPassword")}
              placeholder="8文字以上50文字以内、大文字含む英数字"
              {...register("newPassword", {
                required: "この項目は必須です",
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&'()*+,./:;<=>?@]{8,50}$/,
                  message:
                    "8文字以上50文字以内、大文字含む英数字で入力してください",
                },
                validate: (value) =>
                  value !== getValues("oldPassword") ||
                  "現在のパスワードになっています",
              })}
            />
            {errors.newPassword?.message && (
              <span className="text-red-600 ml-0 w-[460px]">
                {errors.newPassword.message}
              </span>
            )}
            <label className="text-lg font-w6 leading-none text-gray-700 w-full">
              新しいパスワード(確認用)
            </label>
            <input
              className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full border-gray-700"
              type="password"
              value={watch("confirmPassword")}
              placeholder="8文字以上50文字以内、大文字含む英数字"
              {...register("confirmPassword", {
                required: "この項目は必須です",
                validate: (value) =>
                  value === getValues("newPassword") ||
                  "パスワードが一致しません",
              })}
            />
            {errors.confirmPassword?.message && (
              <span className="text-red-600 ml-0 w-[460px]">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>
          <div className="mt-16	pb-20 text-center">
            <Link to={"/merchants"}>
              <button className="inline-block w-36 h-13 border rounded-lg text-sm border-gray-700 text-gray-700">
                戻る
              </button>
            </Link>
            <button
              type="submit"
              className="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
              value="変更"
            >
              変更
            </button>
          </div>
        </form>
      </div>
      <span className="text-xs leading-3 text-center text-gray-600 mt-6">
        © 2022 CAPS株式会社
      </span>
    </div>
  );
});
