import React from "react";
import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import HomeIcon from "../../images/home.png";

const routes = [
  { path: "/", breadcrumb: null },
  { path: "/merchants", breadcrumb: "利用会社一覧" },
  { path: "/merchants/create", breadcrumb: "利用会社登録" },
  { path: "/merchants/detail", breadcrumb: null },
  { path: "/merchants/detail/:id", breadcrumb: "利用会社詳細" },
  { path: "/merchants/detail/:id/edit", breadcrumb: "利用会社編集" },
  {
    path: "/merchants/:id",
    breadcrumb: null,
  },
  {
    path: "/merchants/detail/:id/stress_check_list",
    breadcrumb: "ストレスチェック一覧",
  },
  {
    path: "/merchants/detail/:id/stress_check_list/create",
    breadcrumb: "ストレスチェック登録",
  },
  {
    path: "/merchants/detail/:id/stress_check_list/edit",
    breadcrumb: "ストレスチェック詳細",
  },
  {
    path: "/merchants/detail/:id/stress_check_list/edit/:id",
    breadcrumb: null,
  },

  { path: "/merchants/detail/:id/management_user", breadcrumb: null },
  {
    path: "/merchants/detail/:id/management_user/create",
    breadcrumb: "担当者登録",
  },
];

export const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <React.Fragment>
      {breadcrumbs.map(({ match, breadcrumb }, i) => (
        <div key={i} className="flex justify-center items-center">
          {i === 0 ? (
            <img
              className="inline-block mr-3.5"
              src={HomeIcon}
              alt="home.png"
              width={28}
              height={28}
            />
          ) : null}
          <NavLink to={match.pathname} className=" hover:underline">
            <span className="text-lightGreen-300">{breadcrumb}</span>
          </NavLink>
          {breadcrumbs.length - 1 === i ? null : (
            <span className="text-lightGreen-300 px-3">&gt;</span>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};
