import Company from "../../../images/company-icon.png";

export const SidebarList = [
  {
    id: 1,
    iconImage: Company,
    iconAlt: "icon.png",
    path: "/merchants",
    children: "利用会社一覧",
  },
];
