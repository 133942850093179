import React, { memo } from "react";
import { useLocation } from "react-router-dom";

import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { ReturnHomeButton } from "../../atoms/commons/ReturnHomeButton";
import { Title } from "../../atoms/commons/Title";
import SCIcon from "../../../images/stress-check-list.png";

export const StressCheckEditComplete = memo(() => {
  const currentUrl = useLocation();
  const companyId = currentUrl.pathname.charAt(18);
  return (
    <>
      <Title src={SCIcon}>ストレスチェック詳細</Title>
      <ExplanatoryText>ストレスチェック編集が完了しました</ExplanatoryText>
      <div className="border-t border-lightGray-100">
        <ReturnHomeButton
          path={`/merchants/detail/${companyId}/stress_check_list`}
        >
          ストレスチェック一覧へ戻る
        </ReturnHomeButton>
      </div>
    </>
  );
});
