import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { PasswordChangeRequestType } from "../types/api/user";
import { useLoginUser } from "./useLoginUser";
import {
  PostAuthLogin,
  PostAuthLogout,
  PostPasswordChange,
} from "../api/apiAuth";
import { useLoadingCheck } from "./useLoadingCheck";

export const useAuth = () => {
  const navigate = useNavigate();
  const { setLoginUser } = useLoginUser();
  const { setLoading } = useLoadingCheck();

  const login = useCallback(async (userId: string, userPassword: string) => {
    const data = {
      id: `${userId}`,
      password: `${userPassword}`,
    };

    setLoading(true);
    const res = await PostAuthLogin(data);
    if (res.status !== 200) {
      setLoading(false);
      return alert("ログインに失敗しました");
    }

    setLoading(false);
    setLoginUser(res.data);
    navigate("/stress-checks");
  }, []);

  const logout = useCallback(async () => {
    const res = await PostAuthLogout();

    if (res.status !== 200) {
      return alert("ログアウトに失敗しました");
    }
    navigate("/");
    setLoginUser(null);
    alert("ログアウトしました");
  }, []);

  const updatePassword = useCallback(
    async (data: PasswordChangeRequestType) => {
      const res = await PostPasswordChange(data);
      setLoading(true);

      if (res.status !== 200) {
        setLoading(false);
        return alert("パスワード変更に失敗しました");
      }
      navigate("/merchants");
      setLoading(false);
      alert("パスワード変更に成功しました");
    },
    []
  );

  return { login, logout, updatePassword };
};
