import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  SubmitHandler,
  UseFormRegister,
  UseFormWatch,
  UseFormHandleSubmit,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";

import { Title } from "../../atoms/commons/Title";
import SCIcon from "../../../images/stress-check-list.png";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { StressCheckFormType } from "../../../types/api/stressCheck";
import { StressCheckReturnButton } from "../../atoms/commons/StressCheckReturnButton";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";
import { RegistrationButton } from "../../atoms/commons/RegistrationButton";
import { ErrorMassage } from "../../atoms/commons/ErrorMassage";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { useStressCheck } from "../../../hooks/stressCheck/useStressCheck";
import { StressCheckLimitDateForm } from "../../atoms/stressChecks/StressCheckLimitDateForm";
import { StressCheckEditStartDateForm } from "../../atoms/stressChecks/StressCheckEditStartDateForm";

type EditProps = {
  watch: UseFormWatch<StressCheckFormType>;
  hidePreview: () => void;
  isPreviewVisible: boolean;
  updateStressCheck: () => void;
  register: UseFormRegister<StressCheckFormType>;
  handleSubmit: UseFormHandleSubmit<StressCheckFormType>;
  errors: FieldErrors<StressCheckFormType>;
  onSubmit: SubmitHandler<StressCheckFormType>;
  stressCheckId: string | undefined;
  setValue: UseFormSetValue<StressCheckFormType>;
  isStartDateExaminationBegun: () => boolean;
  setInitialDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  initialDisabled: boolean;
};
export const StressCheckEditForm = ({
  watch,
  hidePreview,
  isPreviewVisible,
  updateStressCheck,
  register,
  onSubmit,
  handleSubmit,
  errors,
  stressCheckId,
  setValue,
  setInitialDisabled,
  initialDisabled,
}: EditProps) => {
  const currentUrl = useLocation();
  const companyId = currentUrl.pathname.charAt(18);
  const { detailStressCheck, fetchStressCheck } = useStressCheck();
  const [loading, setLoading] = useState(true);

  const normalFormStyle =
    "w-[33rem] h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  // 投稿フォームのselectタグのCSS
  const checkFormStyle = "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  // 確認フォームのinputタグのCSS
  const normalSelectFormStyle =
    "border rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none relative";
  // 確認フォームのselectタグのCSS
  const checkSelectFormStyle =
    "rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none disabled:bg-white";

  // 投稿フォームと確認フォームをinput/selectタグを動的に変更させる
  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;
  const formSelectStyle = !isPreviewVisible
    ? normalSelectFormStyle
    : checkSelectFormStyle;
  const startDateColumns = ["startDateYear", "startDateMonth", "startDateDay"];
  const limitDateColumns = ["limitDateYear", "limitDateMonth", "limitDateDay"];

  //投稿フォームと確認フォームの文言を動的に変更させる。
  const formDescription = !isPreviewVisible
    ? "ストレスチェックを編集する場合は、内容を変更後に確認ボタンを押してください"
    : "入力した内容を確認して、編集ボタンを押してください";

  useEffect(() => {
    fetchStressCheck(stressCheckId, companyId, setLoading);
    // detailStressCheckが初期値(0)以外の場合、setValueでフォームを更新する
    if (detailStressCheck.id !== 0) {
      const start_dates = detailStressCheck.start_date.split("/");
      const start_date_year = start_dates[0];
      const start_date_month = start_dates[1];
      const start_date_day = start_dates[2].substring(
        0,
        start_dates[2].indexOf(" ")
      );
      const limit_dates = detailStressCheck.limit_date.split("/");
      const limit_date_year = limit_dates[0];
      const limit_date_month = limit_dates[1];
      const limit_date_day = limit_dates[2].substring(
        0,
        limit_dates[2].indexOf(" ")
      );
      setValue("name", detailStressCheck.name);
      setValue("startDateYear", start_date_year);
      setValue("startDateMonth", start_date_month);
      setValue("startDateDay", start_date_day);
      setValue("limitDateYear", limit_date_year);
      setValue("limitDateMonth", limit_date_month);
      setValue("limitDateDay", limit_date_day);
      const todayDate = new Date();
      const startDate = new Date(
        Number(start_date_year),
        Number(start_date_month) - 1,
        Number(start_date_day)
      );
      if (startDate <= todayDate) {
        setInitialDisabled(true);
      } else {
        setInitialDisabled(false);
      }
    }
  }, [loading]);

  return (
    <>
      <Title src={SCIcon}>ストレスチェック詳細</Title>
      <ExplanatoryText>{formDescription}</ExplanatoryText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-6 pb-8 flex items-center">
          <RequiredFormLabel
            title="ストレスチェック名"
            className="w-52 mr-10 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
          />
          <input
            {...register("name", { required: true })}
            className={formStyle}
            disabled={isPreviewVisible}
            value={watch("name")}
          />
        </div>
        {errors.name?.type === "required" && (
          <ErrorMassage error="required" style="modal" />
        )}
        <div className="pt-6 pb-8 border-t items-center flex relative">
          <RequiredFormLabel
            title="受検開始日"
            className="w-52 mr-10 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
          />
          <StressCheckEditStartDateForm
            defaultValueColumns={startDateColumns}
            watch={watch}
            register={register}
            formSelectStyle={formSelectStyle}
            isPreviewVisible={isPreviewVisible}
            initialDisabled={initialDisabled}
          />
        </div>
        <div className="pt-6 pb-8 border-t items-center flex relative">
          <RequiredFormLabel
            title="受検終了日"
            className="w-52 mr-10 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
          />
          <StressCheckLimitDateForm
            defaultValueColumns={limitDateColumns}
            watch={watch}
            register={register}
            formSelectStyle={formSelectStyle}
            isPreviewVisible={isPreviewVisible}
          />
        </div>
        <div className="pt-10	pb-20 text-center border-t">
          {!isPreviewVisible ? (
            <>
              <StressCheckReturnButton />
              <ConfirmationButton>確認</ConfirmationButton>
            </>
          ) : (
            <>
              <ReturnFormButton returnEvent={hidePreview} />
              <RegistrationButton registrationEvent={updateStressCheck}>
                編集
              </RegistrationButton>
            </>
          )}
        </div>
      </form>
    </>
  );
};
