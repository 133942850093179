import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GetDetailMerchant } from "../../api/apiMerchant";
import { DetailMerchantResponseType } from "../../types/api/merchant";

// 利用会社一覧を取得
export const useFetchDetailMerchant = () => {
  const params = useParams();
  const companyId = params.id;

  const [detailMerchant, setDetailMerchant] =
    useState<DetailMerchantResponseType>({
      data: {
        company: {
          name: "",
          url: "",
          address: "",
          business_content: "",
        },
        person_in_charge: {
          name: "",
          email_address: "",
        },
      },
    });
  const fetchDetailMerchant = useCallback(async () => {
    const res = await GetDetailMerchant(companyId);
    if (res.status !== 200) {
      return alert("利用会社の詳細データ取得に失敗しました");
    }
    setDetailMerchant(res.data);
  }, []);

  useEffect(() => {
    fetchDetailMerchant();
  }, []);

  return {
    companyId,
    detailMerchant,
  };
};
