import React, { useState } from "react";

export const useDate = () => {
  const [year, setDateYear] = useState(new Date().getFullYear());
  const [month, setDateMonth] = useState(new Date().getMonth() + 1);
  const [day, setDateDay] = useState("");

  const setYear = () => {
    // eslint-disable-next-line
    let list = [];
    const nowYear = new Date().getFullYear();
    for (let i = nowYear; i <= nowYear + 10; i++) {
      list.push(i);
    }
    return list;
  };
  const setMonth = () => {
    // eslint-disable-next-line
    let list = [];
    for (let i = 1; i <= 12; i++) {
      list.push(i);
    }
    return list;
  };
  const setDay = () => {
    // eslint-disable-next-line
    let list = [];
    const lastDay = new Date(Number(year), Number(month), 0).getDate();
    for (let i = 1; i <= 31; i++) {
      list.push(i);
    }
    return list;
  };

  return {
    setYear,
    setMonth,
    setDay,
  };
};
