import React, { memo, useState } from "react";
import ReactPaginate from "react-paginate";

import Company from "../../../images/company-icon.png";
import { CreateButton } from "../../atoms/commons/CreateButton";
import { Title } from "../../atoms/commons/Title";
import { useNavigate } from "react-router-dom";
import { MerchantType } from "../../../types/api/merchant";

export const MerchantList = memo((props: { merchantList: MerchantType[] }) => {
  const { merchantList } = props;
  const navigate = useNavigate();
  // 利用会社の順番を降順にする処理
  const reversedMerchants = [...merchantList].reverse();
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(50);

  const handlePageChange = (data: { selected: number }) => {
    // eslint-disable-next-line
    let page_number = data["selected"]; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
    setOffset(page_number * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
  };

  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>利用会社一覧</Title>
        <CreateButton
          path="/merchants/create"
          placementClassName="absolute top-[65px] right-[35px]"
        />
      </div>
      {/* ***************** 4月リリース時は非表示のためコメントアウト ****************************/}
      {/* <div className="h-12 mt-20 md:flex md:justify-between">
        <p className="text-md leading-relaxed ml-14 md:text-center py-2">
          1 - {offset + perPage} 件 / 全 {merchants.length}件中
        </p>
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          表示件数
        </p>
        <CountSelectForm />
        <p className="text-md font-bold text-lightGreen-200 md:text-center py-2">
          検索
        </p>
        <SearchForm />
        <SearchButton />
        </div>
        {/******************************************************************************** */}
      <div className="mt-5 justify-center container flex flex-col">
        <table className="py-5 text-md justify-center container mx-auto">
          <thead>
            <tr className="border-b leading-none text-lightGreen-200">
              <th className="text-left py-5 w-8">ID</th>
              <th className="text-left py-5 w-24">会社名</th>
              <th className="text-left py-5 w-0"></th>
            </tr>
          </thead>
          {reversedMerchants.slice(offset, offset + perPage).map((merchant) => (
            <tbody key={merchant.id}>
              <tr className="border-b hover:bg-lightGreen-100 h-24">
                <td className="text-lightGreen-200 truncate max-w-0">
                  <span title={String(merchant.id)}>{String(merchant.id)}</span>
                </td>
                <td className="text-lightGreen-200 truncate max-w-0">
                  <span title={merchant.name}>{merchant.name}</span>
                </td>
                <td className="mt-6 flex justify-end">
                  <button
                    className="h-10 w-20 text-sm border-lightGreen-200 border rounded-lg text-lightGreen-200 hover:bg-lightGreen-200 hover:text-white"
                    type="button"
                    onClick={() => {
                      navigate(`../detail/${merchant.id}`, {
                        state: { id: merchant.id },
                      });
                    }}
                  >
                    <p className="text-lg font-w6 leading-relaxed">詳細</p>
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <div className="h-40 w-full mt-11 mb-10 flex justify-center">
        <p className="mr-auto text-lg leading-relaxed text-gray-700">
          1 - {offset + perPage} 件 / 全 {merchantList.length}件中
        </p>
        <div className="flex mr-80">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Math.ceil(merchantList.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに。
            marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
            pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
            onPageChange={handlePageChange} // クリック時のfunction
            containerClassName={"w-full inline-flex space-x-1"} // ページネーションであるulに着くクラス名
            activeClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // アクティブなページのliに着くクラス名
            previousClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 「<」のliに着けるクラス名
            nextClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 「>」のliに着けるクラス名
            disabledClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 使用不可の「<,>」に着くクラス名
            breakClassName={
              "w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            } // 「...」に着くクラス名
            pageClassName={
              "pt-1 w-10 h-10 text-lightGreen-200 rounded-lg text-lg leading-relaxed border rounded-lg border-lightGreen-200 align-middle text-justify"
            }
            // aタグのクラス名
            pageLinkClassName={"block w-10 h-full text-center no-underline"}
            previousLinkClassName={"block w-10 h-full text-center no-underline"}
            nextLinkClassName={"block w-10 h-full text-center no-underline"}
            disabledLinkClassName={"block w-10 h-full text-center no-underline"}
            breakLinkClassName={"block w-10 h-full text-center no-underline"}
          />
        </div>
      </div>
    </>
  );
});
