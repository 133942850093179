import React, { memo } from "react";
import { Link } from "react-router-dom";

import { StressCheckType } from "../../../types/api/stressCheck";

export const StressCheckListTable = memo(
  (props: { stressCheck: StressCheckType; companyID: string | undefined }) => {
    const { stressCheck, companyID } = props;

    return (
      <tbody id={String(stressCheck.id)}>
        <tr className="border-b hover:bg-lightGreen-100 h-24">
          <td className="text-lightGreen-200 truncate max-w-0">
            <span title={stressCheck.name} className="truncate">
              {stressCheck.name}
            </span>
          </td>

          <td className="truncate max-w-0">
            <span>{stressCheck.period}</span>
          </td>
          <td className="flex items-center justify-end mt-6">
            <Link
              to={
                `../` +
                `detail/` +
                `${companyID}` +
                `/stress_check_list/edit/` +
                `${stressCheck.id}`
              }
              className="py-1 px-5 rounded-lg focus:outline-none border-lightGreen-200 border hover:bg-lightGreen-200 hover:text-white text-lightGreen-200"
            >
              <span className="text-lg font-w6 leading-relaxed">詳細</span>
            </Link>
          </td>
        </tr>
      </tbody>
    );
  }
);
