import { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { postStressCheck } from "../../api/apiStressCheck";
import {
  StressCheckRequestType,
  StressCheckFormType,
} from "../../types/api/stressCheck";

export const useSaveStressCheck = () => {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const params = useParams();
  const companyId = params.id;

  const [requestData, setRequestData] = useState<StressCheckRequestType>({
    name: "",
    start_date: "",
    limit_date: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<StressCheckFormType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      startDateYear: "2022",
      startDateMonth: "1",
      startDateDay: "1",
      limitDateYear: "2022",
      limitDateMonth: "1",
      limitDateDay: "1",
    },
  });

  const todayDate = new Date();

  // 受検開始日が本日の日付だった場合、startDateAndTodayDateComparisonの条件に通るようにするため +23:59:59にしている
  const startDate = new Date(
    Number(watch("startDateYear")),
    Number(watch("startDateMonth")) - 1,
    Number(watch("startDateDay")),
    +23,
    +59,
    +59
  );

  const limitDate = new Date(
    Number(watch("limitDateYear")),
    Number(watch("limitDateMonth")) - 1,
    Number(watch("limitDateDay"))
  );
  const startDateAndTodayDateComparison = () => {
    if (startDate < todayDate) {
      return true;
    } else {
      return false;
    }
  };

  const startDateLimitDateComparison = () => {
    if (limitDate <= startDate) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit: SubmitHandler<StressCheckFormType> = (data) => {
    if (startDateAndTodayDateComparison() === false) {
      if (startDateLimitDateComparison() === false) {
        setRequestData({
          name: data.name,
          start_date: `${data.startDateYear}/${data.startDateMonth}/${data.startDateDay}`,
          limit_date: `${data.limitDateYear}/${data.limitDateMonth}/${data.limitDateDay}`,
        });
        setIsPreviewVisible(true);
      } else {
        alert(
          "受検終了日が受検開始日より過去の日付もしくは、受検開始日と受検終了日が同じ日付です"
        );
      }
    } else {
      alert("受検開始日が過去の日付です。");
    }
  };

  // SC受検枠登録
  const saveStressCheck = useCallback(
    async (companyId: string | undefined, data: StressCheckRequestType) => {
      const res = await postStressCheck(companyId, data);
      if (res.status !== 201) {
        return alert("登録に失敗しました");
      }
      setIsComplete(true);
    },
    []
  );

  // 投稿フォームのinputタグのCSS
  const normalFormStyle =
    "w-[33rem] h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  // 投稿フォームのselectタグのCSS
  const checkFormStyle = "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  // 確認フォームのinputタグのCSS
  const normalSelectFormStyle =
    "border rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none relative";
  // 確認フォームのselectタグのCSS
  const checkSelectFormStyle =
    "rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none disabled:bg-white";

  // 投稿フォームと確認フォームをinput/selectタグを動的に変更させる
  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;
  const formSelectStyle = !isPreviewVisible
    ? normalSelectFormStyle
    : checkSelectFormStyle;
  const startDateColumns = ["startDateYear", "startDateMonth", "startDateDay"];
  const limitDateColumns = ["limitDateYear", "limitDateMonth", "limitDateDay"];

  //投稿フォームと確認フォームの文言を動的に変更させる。
  const formDescription = !isPreviewVisible
    ? "ストレスチェックの情報を入力し、確認ボタンを押してください"
    : "入力した内容を確認して、登録ボタンを押してください";

  const hidePreview = () => {
    setIsPreviewVisible(false);
  };

  const createStressCheck = () => {
    saveStressCheck(companyId, requestData);
  };

  return {
    isComplete,
    hidePreview,
    createStressCheck,
    onSubmit,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    isPreviewVisible,
    formStyle,
    formSelectStyle,
    startDateColumns,
    limitDateColumns,
    formDescription,
    companyId,
  };
};
