import { useCallback, useEffect, useState } from "react";

import { GetMerchantList } from "../../api/apiMerchant";
import { MerchantType } from "../../types/api/merchant";

// 利用会社一覧を取得
export const useFetchMerchantList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [merchantList, setMerchantList] = useState<Array<MerchantType>>([]);

  const fetchMerchantList = useCallback(async () => {
    const res = await GetMerchantList();
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("社員一覧取得に失敗しました");
    }
    setIsLoading(false);
    setMerchantList(res.data.data);
  }, []);

  useEffect(() => {
    fetchMerchantList();
  }, []);

  return {
    isLoading,
    merchantList,
  };
};
