import React, { FC, memo } from "react";

export const ConfirmationButton: FC = memo(() => {
  return (
    <button
      type="submit"
      className="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
      value="確認"
    >
      確認
    </button>
  );
});
