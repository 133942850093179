import React, { FC, memo } from "react";
import { Link } from "react-router-dom";

type Props = {
  path: string;
  placementClassName: string;
};

export const CreateButton: FC<Props> = memo((props) => {
  const { path, placementClassName } = props;
  return (
    <div className={placementClassName}>
      <Link to={`${path}`}>
        <button className="relative flex-1 border-lightGreen-200 border py-2 px-4 rounded-lg hover:bg-lightGreen-200 hover:text-white text-lightGreen-200 w-[136px] h=[50px]">
          <p className="text-lg font-w6 leading-relaxed">＋ 新規登録</p>
        </button>
      </Link>
    </div>
  );
});
