import React from "react";
import {
  Routes,
  Route,
  RouteProps,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";

import { Login } from "../components/pages/auth/Login";
import { Common } from "../components/templates/Common";
import { ErrorPage } from "../components/pages/ErrorPage";
import { useLoginUser } from "../hooks/useLoginUser";
import { Merchants } from "../components/pages/merchants/Merchant";
import { MerchantCreate } from "../components/pages/merchants/MerchantCreate";
import { MerchantDetail } from "../components/pages/merchants/MerchantDetail";
import { MerchantManagementUserCreate } from "../components/pages/merchantManagementUser/MerchantManagementUserCreate";
import { MerchantEdit } from "../components/pages/merchants/MerchantEdit";
import { PasswordChange } from "../components/pages/auth/PasswordChange";
import { useLoadingCheck } from "../hooks/useLoadingCheck";
import { LoadingPage } from "../components/pages/LoadingPage";
import { StressChecks } from "../components/pages/stressChecks/StressChecks";
import { StressCheckCreate } from "../components/pages/stressChecks/StressCheckCreate";
import { StressCheckEdit } from "../components/pages/stressChecks/StressCheckEdit";

// ログイン情報が必須の場合
const PrivateRoute: React.FC<RouteProps> = () => {
  const { loginUser } = useLoginUser();
  const location = useLocation();
  // PublicRouteに
  return loginUser ? <Outlet /> : <Navigate to="/" state={location.pathname} />;
};

// ログインページに遷移する場合
const PublicRoute: React.FC<RouteProps> = () => {
  const { loginUser } = useLoginUser();
  const location = useLocation();
  let pathName = location.state;
  // ログインページからhomeページに遷移する瞬間は、pathNameがnullなのでhomeページ(merchants)に遷移させる
  if (pathName == null) {
    pathName = "merchants";
  }
  return loginUser ? <Navigate to={`${pathName}`} /> : <Login />;
};

export const Router = () => {
  const { loading } = useLoadingCheck();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<PublicRoute />} />
        <Route path="/password/change" element={<PrivateRoute />}>
          <Route path="/password/change" element={<PasswordChange />} />
        </Route>
        <Route path="/merchants" element={<PrivateRoute />}>
          <Route path="/merchants" element={<Common>{<Merchants />}</Common>} />
          <Route path="/merchants/create" element={<MerchantCreate />} />
          <Route
            path="/merchants/detail/:id"
            element={<Common>{<MerchantDetail />}</Common>}
          />
          <Route
            path="/merchants/detail/:id/edit"
            element={<Common>{<MerchantEdit />}</Common>}
          />
          <Route
            path="/merchants/detail/:id/management_user/create"
            element={<Common>{<MerchantManagementUserCreate />}</Common>}
          />
          <Route
            path="/merchants/detail/:id/stress_check_list"
            element={<Common>{<StressChecks />}</Common>}
          />
          <Route
            path="/merchants/detail/:id/stress_check_list/create"
            element={<Common>{<StressCheckCreate />}</Common>}
          />
          <Route
            path="/merchants/detail/:id/stress_check_list/edit/:id"
            element={<Common>{<StressCheckEdit />}</Common>}
          />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};
