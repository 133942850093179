import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company-icon.png";
import {
  EditMerchantForm,
  MerchantRequestType,
} from "../../../types/api/merchant";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { RegistrationButton } from "../../atoms/commons/RegistrationButton";
import { useMerchant } from "../../../hooks/merchant/useMerchant";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { FormLabel } from "../../atoms/commons/FormLabel";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";

type EditProps = {
  updateMerchantEvent: () => void;
  setUpdateRequestData: React.Dispatch<
    React.SetStateAction<MerchantRequestType>
  >;
  companyId: string | undefined;
};

export const MerchantEditForm = ({
  updateMerchantEvent,
  setUpdateRequestData,
  companyId,
}: EditProps) => {
  const { fetchMerchant, merchant } = useMerchant();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const merchantId = params.id;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EditMerchantForm>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      url: "",
      address: "",
      businessContent: "",
    },
  });

  useEffect(() => {
    fetchMerchant(companyId, setLoading);
    if (merchant.id !== 0) {
      setValue("name", merchant.name);
      setValue("url", merchant.url);
      setValue("address", merchant.address);
      setValue("businessContent", merchant.business_content);
    }
  }, [loading]);

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const onSubmit: SubmitHandler<EditMerchantForm> = (data) => {
    setUpdateRequestData({
      name: data.name,
      url: data.url,
      address: data.address,
      business_content: data.businessContent,
    });
    setIsPreviewVisible(true);
  };

  const hidePreview = () => {
    setIsPreviewVisible(false);
  };

  //  利用会社名投稿・確認フォームのinputタグのCSS
  const normalMerchantNameFormStyle =
    "w-80 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  const checkMerchantNameFormStyle =
    "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  //  URL、住所の投稿・確認フォームのinputタグのCSS
  const normalFormStyle =
    "w-4/5 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  const checkFormStyle = "w-4/5 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  //  事業内容の投稿・確認フォームのtextareaタグのCSS
  const normalTextareaFormStyle =
    "resize-none w-4/5 h-48 px-5 py-1 border rounded-lg text-sm border-lightGreen-200";

  const checkTextareaFormStyle =
    "resize-none w-4/5 h-48 px-5 py-1 rounded-lg text-sm disabled:bg-white";

  // 投稿フォームと確認フォームをinput/textareaタグを動的に変更させる
  const formMerchantNameStyle = !isPreviewVisible
    ? normalMerchantNameFormStyle
    : checkMerchantNameFormStyle;

  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;
  const textareaFormStyle = !isPreviewVisible
    ? normalTextareaFormStyle
    : checkTextareaFormStyle;

  const formDescription = !isPreviewVisible
    ? "利用会社を編集し、確認ボタンを押してください"
    : "入力した内容を確認して、編集ボタンを押してください";

  {
    /********************************************************
     * TODO: リファクタする際にlabelやinputを共通化させる
     *******************************************************/
  }
  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>利用会社編集</Title>
      </div>

      <ExplanatoryText>{formDescription}</ExplanatoryText>

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="会社名"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("name", {
                required: true,
              })}
              className={formMerchantNameStyle}
              disabled={isPreviewVisible}
              value={watch("name")}
            />
            {errors.name?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="URL"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("url", {
                required: true,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("url")}
            />
            {errors.url?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="住所"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("address", {
                required: true,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("address")}
            />
            {errors.address?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="py-5 border-t items-center flex relative">
            <FormLabel
              title="事業内容"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <textarea
              {...register("businessContent")}
              className={textareaFormStyle}
              disabled={isPreviewVisible}
              value={watch("businessContent")}
            />
          </div>

          <div className="mt-10	pb-20 text-center">
            {!isPreviewVisible ? (
              <>
                <button
                  type="submit"
                  onClick={() => navigate(`/merchants/detail/${merchantId}`)}
                  className="inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 text-lightGreen-200"
                >
                  戻る
                </button>
                <ConfirmationButton />
              </>
            ) : (
              <>
                <ReturnFormButton returnEvent={hidePreview} />
                <RegistrationButton registrationEvent={updateMerchantEvent}>
                  編集
                </RegistrationButton>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
