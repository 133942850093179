import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";

import { StressCheckEditForm } from "../../templates/stressChecks/StressCheckEditForm";
import { StressCheckEditComplete } from "../../templates/stressChecks/StressCheckEditComplete";
import { useStressCheck } from "../../../hooks/stressCheck/useStressCheck";
import {
  StressCheckRequestType,
  StressCheckFormType,
} from "../../../types/api/stressCheck";

export const StressCheckEdit = memo(() => {
  const params = useParams();
  const stressCheckId = params.id;
  const currentUrl = useLocation();
  const companyId = currentUrl.pathname.charAt(18);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const { updateStressCheck, isComplete } = useStressCheck();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<StressCheckFormType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      startDateYear: "2022",
      startDateMonth: "1",
      startDateDay: "1",
      limitDateYear: "2022",
      limitDateMonth: "1",
      limitDateDay: "1",
    },
  });

  const [initialDisabled, setInitialDisabled] = useState(false);

  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [updateRequestData, setRequestData] = useState<StressCheckRequestType>({
    name: "",
    start_date: "",
    limit_date: "",
  });

  const today = new Date();
  const startSelectDate = new Date(
    Number(watch("startDateYear")),
    Number(watch("startDateMonth")) - 1,
    Number(watch("startDateDay")),
    +23,
    +59,
    +59
  );

  const limitSelectDate = new Date(
    Number(watch("limitDateYear")),
    Number(watch("limitDateMonth")) - 1,
    Number(watch("limitDateDay"))
  );

  const isStartDateExaminationBegun = () => {
    if (startSelectDate < today) {
      return true;
    } else {
      return false;
    }
  };

  const startDateLimitDateComparison = () => {
    if (limitSelectDate <= startSelectDate) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit: SubmitHandler<StressCheckFormType> = (data) => {
    if (isStartDateExaminationBegun() === false) {
      if (startDateLimitDateComparison() === false) {
        setRequestData({
          name: data.name,
          start_date: `${data.startDateYear}/${data.startDateMonth}/${data.startDateDay}`,
          limit_date: `${data.limitDateYear}/${data.limitDateMonth}/${data.limitDateDay}`,
        });
        setIsPreviewVisible(true);
      } else {
        alert(
          "受検開始日と受検終了日が同じ、もしくは受検終了日が受検開始日より過去の日付になっています"
        );
      }
    } else {
      alert("受検開始日が過去の日付です。");
    }
  };

  const hidePreview = () => {
    setIsPreviewVisible(false);
  };
  const updateStressCheckEvent = () => {
    updateStressCheck(stressCheckId, companyId, updateRequestData);
  };

  useLayoutEffect(() => {
    if (isPreviewVisible == true) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [isPreviewVisible, isComplete]);
  return (
    <>
      {!isComplete ? (
        <StressCheckEditForm
          watch={watch}
          hidePreview={hidePreview}
          isPreviewVisible={isPreviewVisible}
          updateStressCheck={updateStressCheckEvent}
          register={register}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          errors={errors}
          stressCheckId={stressCheckId}
          setValue={setValue}
          isStartDateExaminationBegun={isStartDateExaminationBegun}
          setInitialDisabled={setInitialDisabled}
          initialDisabled={initialDisabled}
        />
      ) : (
        <StressCheckEditComplete />
      )}
    </>
  );
});
