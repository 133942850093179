import { AxiosResponse } from "axios";

import {
  DetailMerchantResponseType,
  MerchantListResponseType,
  MerchantManagementUserRequest,
  MerchantRequestType,
  MerchantResponseType,
} from "../types/api/merchant";
import { api } from "./axiosConfig";

// 登録済みの会社リスト取得
export const GetMerchantList = () => {
  return api
    .get<MerchantListResponseType>("admin/company")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 登録する会社データ送信
export const PostMerchant = (data: MerchantRequestType) => {
  return api
    .post("/admin/company", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 会社詳細データ取得
export const GetDetailMerchant = (companyId: string | undefined) => {
  return api
    .get<DetailMerchantResponseType>(`admin/company/${companyId}/detail`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 登録する担当者データ送信
export const PostMerchantUser = (data: MerchantManagementUserRequest) => {
  return api
    .post("/admin/merchant_user", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 会社詳細情報の取得
export const GetMerchant = (companyId: string | undefined) => {
  return api
    .get<MerchantResponseType>(`admin/company/${companyId}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// 編集する会社データの送信
export const PutMerchant = (
  companyId: string | undefined,
  data: MerchantRequestType
) => {
  return api
    .put(`admin/company/${companyId}`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
