import React, { FC, memo } from "react";
import { Link } from "react-router-dom";

import editPen from "../../../images/edit-pen.png";

type Props = {
  path: string;
};

export const EditButton: FC<Props> = memo((props) => {
  const { path } = props;
  return (
    <div className="top-[65px] right-[35px]">
      <Link to={`${path}`}>
        <button
          className="w-28 h-12 border-lightGreen-200 text-lightGreen-200 hover:bg-lightGreen-100 border py-3
        pl-12 pr-7 rounded-lg relative"
        >
          編集
          <img
            className="w-5 h-5 absolute top-0 left-0 mt-3 ml-5"
            src={editPen}
          ></img>
        </button>
      </Link>
    </div>
  );
});
