import React, { memo, useState } from "react";
import { useMerchant } from "../../../hooks/merchant/useMerchant";

import { MerchantManagementUserRequest } from "../../../types/api/merchant";
import { MerchantManagementUserCreateForm } from "../../templates/merchantManagementUser/ManagementUserCreateForm";
import { MerchantManagementUserComplete } from "../../templates/merchantManagementUser/MerchantManagementUserComplete";

export const MerchantManagementUserCreate = memo(() => {
  const [requestData, setRequestData] = useState<MerchantManagementUserRequest>(
    {
      company_id: "",
      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      email_address: "",
    }
  );
  const { isComplete, saveMerchantManagementUser } = useMerchant();

  const saveMerchantManagementUserEvent = () => {
    saveMerchantManagementUser(requestData);
  };

  return (
    <>
      {!isComplete ? (
        <MerchantManagementUserCreateForm
          createEmployee={saveMerchantManagementUserEvent}
          setRequestData={setRequestData}
        />
      ) : (
        <MerchantManagementUserComplete />
      )}
    </>
  );
});
