import React, { memo } from "react";
import { useParams } from "react-router-dom";

import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { ReturnHomeButton } from "../../atoms/commons/ReturnHomeButton";
import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company-icon.png";

export const MerchantEditComplete = memo(() => {
  const params = useParams();
  const merchantId = params.id;

  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>利用会社者編集</Title>
      </div>

      <ExplanatoryText>利用会社の編集が完了しました</ExplanatoryText>
      <div className="border-t">
        <ReturnHomeButton path={`/merchants/detail/${merchantId}`}>
          利用会社詳細へ戻る
        </ReturnHomeButton>
      </div>
    </>
  );
});
