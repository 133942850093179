import React from "react";

import { CreateButton } from "../../atoms/commons/CreateButton";
import { EditButton } from "../../atoms/commons/EditButton";
import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company-icon.png";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { FormLabel } from "../../atoms/commons/FormLabel";
import { useFetchDetailMerchant } from "../../../hooks/merchant/useFetchDetailMerchant";
import { useNavigate } from "react-router-dom";

export const MerchantDetailForm = () => {
  const { detailMerchant, companyId } = useFetchDetailMerchant();

  // 担当者が存在していなかったら、動的にHTMLを変化させるためfalseを代入する
  const personInCharge =
    detailMerchant.data.person_in_charge.email_address === "未設定"
      ? false
      : detailMerchant.data.person_in_charge;

  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>利用会社詳細</Title>

        <div className="flex mt-14">
          <div className="mr-8">
            <button
              className="h-12 w-64 text-sm focus:outline-none border-lightGreen-200 border rounded-lg text-lightGreen-200 hover:bg-lightGreen-200 hover:text-white"
              type="button"
              onClick={() => {
                navigate(`../detail/${companyId}/stress_check_list`, {
                  state: { id: companyId },
                });
              }}
            >
              <p className="text-lg max-w-20 leading-relaxed">
                ストレスチェック一覧
              </p>
            </button>
          </div>
          <EditButton path={`/merchants/detail/${companyId}/edit`} />
        </div>
      </div>
      <ExplanatoryText>
        会社情報を編集する場合は、編集ボタンを押してください。
      </ExplanatoryText>

      <div>
        <div className="py-5 min-h-90 border-t flex items-center">
          <FormLabel
            title="会社名"
            className="w-44 min-w-[100px] text-sm font-semibold text-lightGreen-200"
          />
          <span className="pl-5 text-sm max-w-[800px]">
            {detailMerchant.data.company.name}
          </span>
        </div>
        <div className="py-5 border-t flex items-center">
          <FormLabel
            title="URL"
            className="w-44 min-w-[100px] text-sm font-semibold text-lightGreen-200"
          />
          <span className="pl-5 text-sm max-w-[800px]">
            {detailMerchant.data.company.url}
          </span>
        </div>
        <div className="py-5 border-t flex items-center">
          <FormLabel
            title="住所"
            className="w-44 min-w-[100px] text-sm font-semibold text-lightGreen-200"
          />
          <span className="pl-5 text-sm max-w-[800px]">
            {detailMerchant.data.company.address}
          </span>
        </div>
        <div className="py-5 border-y flex">
          <FormLabel
            title="事業内容"
            className="w-44 min-w-[100px] mt-2 text-sm font-semibold text-lightGreen-200"
          />
          <span className="pl-5 text-sm max-w-[800px]">
            {detailMerchant.data.company.business_content}
          </span>
        </div>

        {personInCharge ? (
          <div className="mt-20 mb-7 pb-28">
            <div className="flex border-b">
              <span className="pb-7 text-2xl font-semibold text-lightGreen-200">
                担当者情報
              </span>
            </div>
            <div className="py-5 min-h-90 border-b flex items-center">
              <FormLabel
                title="氏名"
                className="w-44 min-w-[100px] text-sm font-semibold text-lightGreen-200"
              />
              <span className="pl-5 text-sm max-w-[800px]">
                {personInCharge.name}
              </span>
            </div>
            <div className="py-5 min-h-90 border-b flex items-center">
              <FormLabel
                title="メールアドレス"
                className="w-44 min-w-[100px] text-sm font-semibold text-lightGreen-200"
              />
              <span className="pl-5 text-sm max-w-[800px]">
                {personInCharge.email_address}
              </span>
            </div>
          </div>
        ) : (
          <div className="mt-20 mb-7 pb-28 z-[-2]">
            <div className="flex border-b md:justify-between">
              <span className="pb-7 text-2xl font-semibold text-lightGreen-200">
                担当者情報
              </span>
              <CreateButton
                path={`/merchants/detail/${companyId}/management_user/create`}
                placementClassName="top-[65px] right-[35px] rounded-lg"
              />
            </div>
            <div className="flex justify-center items-center py-14">
              登録されていません。
            </div>
          </div>
        )}
      </div>
    </>
  );
};
