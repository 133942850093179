import React, { FC } from "react";

type Props = {
  returnEvent: () => void;
};

export const ReturnFormButton: FC<Props> = (props) => {
  const { returnEvent } = props;

  return (
    <button
      type="submit"
      onClick={returnEvent}
      className="inline-block w-36 h-13 border rounded-lg border-lightGreen-200"
    >
      <span className="text-lightGreen-200 text-sm">戻る</span>
    </button>
  );
};
