import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getStressCheckList } from "../../api/apiStressCheck";
import {
  StressCheckResponseType,
  StressCheckType,
} from "../../types/api/stressCheck";

export const useFetchStressCheckList = () => {
  const [stressCheckList, setStressCheck] = useState<StressCheckType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const companyID = params.id;

  const fetchStressCheckList = useCallback(async () => {
    const res = await getStressCheckList(companyID);
    if (res.status !== 200) {
      setIsLoading(false);
      return alert("社員一覧取得に失敗しました");
    }
    const stressCheckList: StressCheckType[] =
      res.data.data.stress_check_frames.map((SC: StressCheckResponseType) => {
        return {
          id: SC.id,
          name: SC.name,
          period:
            SC.start_date.slice(0, -3) +
            " ~ " +
            SC.limit_date.slice(0, -3) +
            " (JST)",
          status: SC.status,
        };
      });
    setStressCheck(stressCheckList);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchStressCheckList();
  }, []);

  return {
    isLoading,
    stressCheckList,
    companyID,
  };
};
