import React, { Children, FC, memo } from "react";

type Props = {
  registrationEvent: (e: React.MouseEvent) => void;
  children: string;
};

export const RegistrationButton: FC<Props> = memo((props) => {
  const { registrationEvent, children } = props;

  return (
    <button
      type="submit"
      onClick={registrationEvent}
      className="inline-block w-36 h-13 ml-7 border rounded-lg text-sm border-lightGreen-200 bg-lightGreen-200 text-white"
    >
      {children}
    </button>
  );
});
