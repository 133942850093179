import React, { memo } from "react";

import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { ReturnHomeButton } from "../../atoms/commons/ReturnHomeButton";
import { Title } from "../../atoms/commons/Title";
import SCIcon from "../../../images/stress-check-list.png";
import { useParams } from "react-router-dom";

export const StressCheckCreateFormComplete = memo(
  (props: { companyId: string | undefined }) => {
    const { companyId } = props;
    return (
      <>
        <Title src={SCIcon}>ストレスチェック登録</Title>
        <ExplanatoryText>ストレスチェックの登録が完了しました</ExplanatoryText>
        <div className="border-t border-lightGray-100">
          <ReturnHomeButton
            path={`/merchants/detail/${companyId}/stress_check_list`}
          >
            ストレスチェック一覧へ戻る
          </ReturnHomeButton>
        </div>
      </>
    );
  }
);
