import React, { memo } from "react";

import { useFetchStressCheckList } from "../../../hooks/stressCheck/useFetchStressCheckList";
import { usePaginate } from "../../../hooks/usePaginate";
import { LoadingTemplate } from "../../templates/LoadingTemplate";
import { StressCheckList } from "../../templates/stressChecks/StressCheckList";

export const StressChecks = memo(() => {
  const { stressCheckList, companyID, isLoading } = useFetchStressCheckList();
  const paginateObj = usePaginate();

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <StressCheckList
          paginateObj={paginateObj}
          stressCheckList={stressCheckList}
          companyID={companyID}
        />
      )}
    </>
  );
});
