import React, { memo, useLayoutEffect, useRef } from "react";

import { StressCheckCreateForm } from "../../templates/stressChecks/StressCheckCreateForm";
import { StressCheckCreateFormComplete } from "../../templates/stressChecks/StressCheckCreateFormComplete";
import { useSaveStressCheck } from "../../../hooks/stressCheck/useSaveStressCheck";

export const StressCheckCreate = memo(() => {
  const saveStressCheckObj = useSaveStressCheck();

  const scrollBottomRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (saveStressCheckObj.isPreviewVisible == true) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [saveStressCheckObj.isPreviewVisible, saveStressCheckObj.isComplete]);

  return (
    <>
      <div
        className="bg-white relative z-[-2] rounded-lg w-4/5 min-h-full px-9"
        ref={scrollBottomRef}
      >
        {!saveStressCheckObj.isComplete ? (
          <StressCheckCreateForm saveStressCheckObj={saveStressCheckObj} />
        ) : (
          <StressCheckCreateFormComplete
            companyId={saveStressCheckObj.companyId}
          />
        )}
      </div>
    </>
  );
});
