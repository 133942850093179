import { useCallback, useState } from "react";

import {
  StressCheckRequestType,
  StressCheckEditRequestType,
} from "../../types/api/stressCheck";
import { getStressCheck, putStressCheck } from "../../api/apiStressCheck";

export const useStressCheck = () => {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [detailStressCheck, setDetailStressCheck] =
    useState<StressCheckEditRequestType>({
      id: 0,
      name: "",
      start_date: "",
      limit_date: "",
    });

  // SC受検枠取得
  const fetchStressCheck = useCallback(
    async (
      stressCheckId: string | undefined,
      companyId: string | undefined,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const res = await getStressCheck(stressCheckId, companyId);
      if (res.status !== 200) {
        return alert("ストレスチェックの詳細データ取得に失敗しました。");
      }
      setDetailStressCheck(res.data);
      setLoading(false);
    },
    []
  );

  // SC受検枠更新
  const updateStressCheck = useCallback(
    async (
      stressCheckId: string | undefined,
      companyId: string | undefined,
      data: StressCheckRequestType
    ) => {
      const res = await putStressCheck(stressCheckId, companyId, data);
      if (res.status !== 200) {
        return alert("ストレスチェック編集に失敗しました");
      }
      setIsComplete(true);
    },
    []
  );

  return {
    isComplete,
    detailStressCheck,
    fetchStressCheck,
    updateStressCheck,
  };
};
