import React, { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
  src: string;
};

export const Title: FC<Props> = memo((props) => {
  const { children, src } = props;
  return (
    <div className="flex">
      <img className="w-14 h-16 mt-14" src={src} />
      <span className="text-3xl font-bold leading-relaxed  text-lightGreen-200 block mt-16 ml-4">
        {children}
      </span>
    </div>
  );
});
