import { AxiosResponse } from "axios";

import {
  StressCheckListResponseType,
  StressCheckRequestType,
} from "../types/api/stressCheck";
import { api } from "./axiosConfig";

// ストレスチェック一覧取得
export const getStressCheckList = (companyID: string | undefined) => {
  return api
    .get<StressCheckListResponseType>(`admin/company/${companyID}/stresschecks`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const postStressCheck = (
  companyId: string | undefined,
  data: StressCheckRequestType
) => {
  return api
    .post(`/admin/company/${companyId}/stresscheck`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getStressCheck = (
  stressCheckId: string | undefined,
  companyId: string | undefined
) => {
  return api
    .get(`/admin/company/${companyId}/stresscheck/${stressCheckId}`)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const putStressCheck = (
  stressCheckId: string | undefined,
  companyId: string | undefined,
  data: StressCheckRequestType
) => {
  return api
    .put(`/admin/company/${companyId}/stresscheck/${stressCheckId}`, data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
