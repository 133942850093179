import React from "react";
import {
  SubmitHandler,
  UseFormRegister,
  UseFormWatch,
  UseFormHandleSubmit,
  FieldError,
} from "react-hook-form";

import { Title } from "../../atoms/commons/Title";
import SCIcon from "../../../images/stress-check-list.png";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { StressCheckFormType } from "../../../types/api/stressCheck";
import { StressCheckCreateStartDateForm } from "../../atoms/stressChecks/StressCheckCreateStartDateForm";
import { StressCheckReturnButton } from "../../atoms/commons/StressCheckReturnButton";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";
import { RegistrationButton } from "../../atoms/commons/RegistrationButton";
import { ErrorMassage } from "../../atoms/commons/ErrorMassage";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { StressCheckLimitDateForm } from "../../atoms/stressChecks/StressCheckLimitDateForm";

type InputProps = {
  saveStressCheckObj: {
    isPreviewVisible: boolean;
    register: UseFormRegister<StressCheckFormType>;
    handleSubmit: UseFormHandleSubmit<StressCheckFormType>;
    watch: UseFormWatch<StressCheckFormType>;
    formState: {
      errors: {
        id?: FieldError | undefined;
        name?: FieldError | undefined;
        startDateYear?: FieldError | undefined;
        startDateMonth?: FieldError | undefined;
        startDateDay?: FieldError | undefined;
        limitDateYear?: FieldError | undefined;
        limitDateMonth?: FieldError | undefined;
        limitDateDay?: FieldError | undefined;
      };
    };
    formStyle: string;
    formSelectStyle: string;
    startDateColumns: string[];
    limitDateColumns: string[];
    formDescription: string;
    hidePreview: () => void;
    createStressCheck: () => void;
    onSubmit: SubmitHandler<StressCheckFormType>;
  };
};

export const StressCheckCreateForm = ({ saveStressCheckObj }: InputProps) => {
  return (
    <>
      <Title src={SCIcon}>ストレスチェック登録</Title>
      <ExplanatoryText>{saveStressCheckObj.formDescription}</ExplanatoryText>
      <form
        onSubmit={saveStressCheckObj.handleSubmit(saveStressCheckObj.onSubmit)}
      >
        <div className="pt-6 pb-8 flex items-center">
          <RequiredFormLabel
            title="ストレスチェック名"
            className="w-52 mr-10 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
          />
          <input
            {...saveStressCheckObj.register("name", { required: true })}
            className={saveStressCheckObj.formStyle}
            disabled={saveStressCheckObj.isPreviewVisible}
            value={saveStressCheckObj.watch("name")}
          />
        </div>
        {saveStressCheckObj.formState.errors.name?.type === "required" && (
          <ErrorMassage error="required" style="modal" />
        )}
        <div className="pt-6 pb-8 border-t items-center flex relative">
          <RequiredFormLabel
            title="受検開始日"
            className="w-52 mr-10 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
          />
          <StressCheckCreateStartDateForm
            defaultValueColumns={saveStressCheckObj.startDateColumns}
            watch={saveStressCheckObj.watch}
            register={saveStressCheckObj.register}
            formSelectStyle={saveStressCheckObj.formSelectStyle}
            isPreviewVisible={saveStressCheckObj.isPreviewVisible}
          />
        </div>
        <div className="pt-6 pb-8 border-t items-center flex relative">
          <RequiredFormLabel
            title="受検終了日"
            className="w-52 mr-10 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
          />
          <StressCheckLimitDateForm
            defaultValueColumns={saveStressCheckObj.limitDateColumns}
            watch={saveStressCheckObj.watch}
            register={saveStressCheckObj.register}
            formSelectStyle={saveStressCheckObj.formSelectStyle}
            isPreviewVisible={saveStressCheckObj.isPreviewVisible}
          />
        </div>
        <div className="pt-10	pb-20 text-center border-t">
          {!saveStressCheckObj.isPreviewVisible ? (
            <>
              <StressCheckReturnButton />
              <ConfirmationButton>確認</ConfirmationButton>
            </>
          ) : (
            <>
              <ReturnFormButton returnEvent={saveStressCheckObj.hidePreview} />
              <RegistrationButton
                registrationEvent={saveStressCheckObj.createStressCheck}
              >
                登録
              </RegistrationButton>
            </>
          )}
        </div>
      </form>
    </>
  );
};
