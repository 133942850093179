import React, { FC } from "react";

type FormLabelListProps = {
  title: string;
  className: string;
};

export const FormLabel: FC<FormLabelListProps> = (props) => {
  const { title, className } = props;
  return (
    <>
      <span className={className}>{title}</span>
    </>
  );
};
