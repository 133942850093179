import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";

import { useMerchant } from "../../../hooks/merchant/useMerchant";
import { MerchantRequestType } from "../../../types/api/merchant";
import { MerchantEditComplete } from "../../templates/merchants/MerchantEditComplete";
import { MerchantEditForm } from "../../templates/merchants/MerchantEditForm";

export const MerchantEdit = memo(() => {
  const { isComplete, updateMerchant } = useMerchant();
  const [updateRequestData, setUpdateRequestData] =
    useState<MerchantRequestType>({
      name: "",
      url: "",
      address: "",
      business_content: "",
    });
  const params = useParams();
  const companyId = params.id;

  const updateMerchantEvent = () => {
    updateMerchant(updateRequestData, companyId);
  };

  return (
    <>
      {!isComplete ? (
        <MerchantEditForm
          updateMerchantEvent={updateMerchantEvent}
          setUpdateRequestData={setUpdateRequestData}
          companyId={companyId}
        />
      ) : (
        <MerchantEditComplete />
      )}
    </>
  );
});
