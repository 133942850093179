import React, { memo, useLayoutEffect, useRef, useState } from "react";

import { useMerchant } from "../../../hooks/merchant/useMerchant";
import { MerchantRequestType } from "../../../types/api/merchant";
import { Footer } from "../../templates/Footer";
import { Header } from "../../templates/Header";
import { MerchantCreateComplete } from "../../templates/merchants/MerchantCreateComplete";
import { MerchantCreateForm } from "../../templates/merchants/MerchantCreateForm";
import { Sidebar } from "../../templates/sidebar/Sidebar";

export const MerchantCreate = memo(() => {
  const { isComplete, saveMerchant } = useMerchant();
  const [createRequestData, setCreateRequestData] =
    useState<MerchantRequestType>({
      name: "",
      url: "",
      address: "",
      business_content: "",
    });

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const createMerchantEvent = () => {
    saveMerchant(createRequestData);
  };

  // スクロール時にscrollIntoViewを使用するためにて定義してる
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isPreviewVisible == true) {
      scrollBottomRef?.current?.scrollIntoView();
    }
  }, [isPreviewVisible, isComplete]);

  return (
    <>
      <Sidebar />
      <div className="main box-border fixed overflow-auto bg-lightGreen-100 ml-64 h-full w-full px-9">
        <Header />
        <div
          className="bg-white relative z-[-2] rounded-lg w-4/5 min-h-full px-9"
          ref={scrollBottomRef}
        >
          {!isComplete ? (
            <MerchantCreateForm
              setIsPreviewVisible={setIsPreviewVisible}
              isPreviewVisible={isPreviewVisible}
              createMerchantEvent={createMerchantEvent}
              setCreateRequestData={setCreateRequestData}
            />
          ) : (
            <MerchantCreateComplete />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
});
