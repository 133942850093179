import React from "react";

import { MerchantDetailForm } from "../../templates/merchants/MerchantDetailForm";

export const MerchantDetail = () => {
  return (
    <>
      <MerchantDetailForm />
    </>
  );
};
