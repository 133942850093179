import React from "react";
import { NavLink } from "react-router-dom";

import Logo from "../../../images/logo.png";
import { SidebarList } from "./SidebarList";

export const Sidebar = () => {
  return (
    <div className="sidebar box-border fixed justify-center overflow-auto shadow h-full min-w-[255px] p-5">
      <div className="sidebar-logo flex justify-center mb-5">
        <NavLink to="/">
          <img src={Logo} alt="logo.png" width={200} height={100} />
        </NavLink>
      </div>
      <ul className="sidebar-items">
        {SidebarList.map((nav, i) => (
          <li
            key={nav.id}
            className={
              SidebarList.length - 1 === i
                ? "sidebar-item flex py-5 border-y border-ligthGray:100 border-solid"
                : "sidebar-item flex py-5 border-t border-ligthGray:100 border-solid"
            }
          >
            <NavLink
              className={"flex items-center cursor-pointer"}
              to={nav.path}
            >
              <img
                src={nav.iconImage}
                alt={nav.iconAlt}
                width={50}
                height={50}
              />
              <span className="pl-3 text-base font-bold leading-5">
                {nav.children}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
