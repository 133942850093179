import React, { memo } from "react";

import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { ReturnHomeButton } from "../../atoms/commons/ReturnHomeButton";
import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company-icon.png";

export const MerchantCreateComplete = memo(() => {
  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>利用会社者登録</Title>
      </div>

      <ExplanatoryText>利用会社の登録が完了しました</ExplanatoryText>
      <div className="border-t">
        <ReturnHomeButton path="/merchants">
          利用会社一覧へ戻る
        </ReturnHomeButton>
      </div>
    </>
  );
});
