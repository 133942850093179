import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company-icon.png";
import {
  EditMerchantForm,
  MerchantRequestType,
} from "../../../types/api/merchant";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { FormLabel } from "../../atoms/commons/FormLabel";
import { RegistrationButton } from "../../atoms/commons/RegistrationButton";
import { ReturnButton } from "../../atoms/commons/ReturnButton";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";

type EditProps = {
  setIsPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  createMerchantEvent: () => void;
  isPreviewVisible: boolean;
  setCreateRequestData: React.Dispatch<
    React.SetStateAction<MerchantRequestType>
  >;
};

export const MerchantCreateForm = ({
  setIsPreviewVisible,
  isPreviewVisible,
  createMerchantEvent,
  setCreateRequestData,
}: EditProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditMerchantForm>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      name: "",
      url: "",
      address: "",
      businessContent: "",
    },
  });

  const onSubmit: SubmitHandler<EditMerchantForm> = (data) => {
    setCreateRequestData({
      name: data.name,
      url: data.url,
      address: data.address,
      business_content: data.businessContent,
    });
    setIsPreviewVisible(true);
  };

  const hidePreview = () => {
    setIsPreviewVisible(false);
  };

  //  利用会社名投稿・確認フォームのinputタグのCSS
  const normalMerchantNameFormStyle =
    "w-80 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  const checkMerchantNameFormStyle =
    "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  //  URL、住所の投稿・確認フォームのinputタグのCSS
  const normalFormStyle =
    "w-4/5 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  const checkFormStyle = "w-4/5 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  //  事業内容の投稿・確認フォームのtextareaタグのCSS
  const normalTextareaFormStyle =
    "resize-none w-4/5 h-48 px-5 py-1 border rounded-lg text-sm border-lightGreen-200";
  const checkTextareaFormStyle =
    "resize-none w-4/5 h-48 px-5 py-1 rounded-lg text-sm  disabled:bg-white";

  // 投稿フォームと確認フォームをinput/textareaタグを動的に変更させる
  const formMerchantNameStyle = !isPreviewVisible
    ? normalMerchantNameFormStyle
    : checkMerchantNameFormStyle;

  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;
  const textareaFormStyle = !isPreviewVisible
    ? normalTextareaFormStyle
    : checkTextareaFormStyle;

  //投稿フォームと確認フォームの文言を動的に変更させる。
  const formDescription = !isPreviewVisible
    ? "利用会社の情報を入力し、確認ボタンを押してください"
    : "入力した内容を確認して、登録ボタンを押してください";

  {
    /********************************************************
     * TODO: リファクタする際にlabelやinputを共通化させる
     *******************************************************/
  }
  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>利用会社登録</Title>
      </div>

      <ExplanatoryText>{formDescription}</ExplanatoryText>

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-6 pb-8 border-t items-center flex relative">
            <RequiredFormLabel
              title="会社名"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("name", {
                required: true,
              })}
              className={formMerchantNameStyle}
              disabled={isPreviewVisible}
              value={watch("name")}
            />
            {errors.name?.types?.required && (
              <span className="text-red-600 absolute bottom-1 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="pt-6 pb-8 border-t items-center flex relative">
            <RequiredFormLabel
              title="URL"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("url", {
                required: true,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("url")}
            />
            {errors.url?.types?.required && (
              <span className="text-red-600 absolute bottom-1 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="pt-6 pb-9 border-t items-center flex relative">
            <RequiredFormLabel
              title="住所"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("address", {
                required: true,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("address")}
            />
            {errors.address?.types?.required && (
              <span className="text-red-600 absolute bottom-1 left-0">
                この項目は必須です。
              </span>
            )}
          </div>

          <div className="pt-9 border-t items-center flex relative">
            <FormLabel
              title="事業内容"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <textarea
              {...register("businessContent")}
              className={textareaFormStyle}
              disabled={isPreviewVisible}
              value={watch("businessContent")}
            />
          </div>

          <div className="mt-10	pb-20 text-center">
            {!isPreviewVisible ? (
              <>
                <ReturnButton />
                <ConfirmationButton />
              </>
            ) : (
              <>
                <ReturnFormButton returnEvent={hidePreview} />
                <RegistrationButton registrationEvent={createMerchantEvent}>
                  登録
                </RegistrationButton>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
