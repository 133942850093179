import React, { memo } from "react";

export const ErrorMassage = memo((props: { error: string; style: string }) => {
  const { error, style } = props;

  const errorMassageHandler = (error: string) => {
    switch (error) {
      case "required":
        return "この項目は必須です。";
      default:
        return "適切な値を入力してください。";
    }
  };

  const styleHandler = (style: string) => {
    switch (style) {
      case "stressCheck":
        return "text-red-600 absolute bottom-0 left-0";
      default:
        return "text-red-600";
    }
  };

  return (
    <span className={styleHandler(style)}>{errorMassageHandler(error)}</span>
  );
});
