import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { Title } from "../../atoms/commons/Title";
import Company from "../../../images/company-icon.png";
import {
  MerchantManagementUserForm,
  MerchantManagementUserRequest,
} from "../../../types/api/merchant";
import { ConfirmationButton } from "../../atoms/commons/ConfirmationButton";
import { RegistrationButton } from "../../atoms/commons/RegistrationButton";
import { ExplanatoryText } from "../../atoms/commons/ExplanatoryText";
import { RequiredFormLabel } from "../../atoms/commons/RequiredFormLabel";
import { ReturnFormButton } from "../../atoms/commons/ReturnFormButton";

type InputProps = {
  createEmployee: () => void;
  setRequestData: React.Dispatch<
    React.SetStateAction<MerchantManagementUserRequest>
  >;
};

export const MerchantManagementUserCreateForm = ({
  createEmployee,
  setRequestData,
}: InputProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const companyId = params.id;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<MerchantManagementUserForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    defaultValues: {
      companyId: "",
      lastName: "",
      firstName: "",
      lastNameKana: "",
      firstNameKana: "",
      emailAddress: "",
    },
  });

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const onSubmit: SubmitHandler<MerchantManagementUserForm> = (data) => {
    setRequestData({
      company_id: companyId,
      last_name: data.lastName,
      first_name: data.firstName,
      last_name_kana: data.lastNameKana,
      first_name_kana: data.firstNameKana,
      email_address: data.emailAddress,
    });
    setIsPreviewVisible(true);
  };

  const hidePreview = () => {
    setIsPreviewVisible(false);
  };

  // 投稿フォームのinputタグのCSS
  const normalFormStyle =
    "w-80 h-13 pl-5 border rounded-lg text-sm border-lightGreen-200";
  // 投稿フォームのselectタグのCSS
  const checkFormStyle = "w-80 h-13 pl-5 rounded-lg text-sm disabled:bg-white";

  // 投稿フォームと確認フォームをinput/タグを動的に変更させる
  const formStyle = !isPreviewVisible ? normalFormStyle : checkFormStyle;
  const formDescription = !isPreviewVisible
    ? "担当者の情報を入力し、確認ボタンを押してください。"
    : "入力した内容を確認して、登録ボタンを押してください";

  {
    /********************************************************
     * TODO: リファクタする際にlabelやinputを共通化させる
     *******************************************************/
  }
  return (
    <>
      <div className="flex justify-between min-h-147">
        <Title src={Company}>担当者登録</Title>
      </div>

      <ExplanatoryText>{formDescription}</ExplanatoryText>

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title="姓"
                  className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("lastName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("lastName")}
                />
              </div>
              {errors.lastName?.types?.required && (
                <span className="text-red-600 absolute bottom-0 left-0">
                  この項目は必須です。
                </span>
              )}
            </div>
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title="名"
                  className="ml-28 w-28 min-w-[100px] text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("firstName", { required: true })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("firstName")}
                />
              </div>
              {errors.firstName?.types?.required && (
                <span className="text-red-600 ml-28 absolute bottom-0">
                  この項目は必須です。
                </span>
              )}
            </div>
          </div>
          <div className="py-5 flex border-t relative">
            <div>
              <div className="flex items-center">
                <RequiredFormLabel
                  title="姓 (カナ)"
                  className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
                />
                <input
                  {...register("lastNameKana", {
                    required: true,
                    pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                  })}
                  className={formStyle}
                  disabled={isPreviewVisible}
                  value={watch("lastNameKana")}
                />
              </div>
              {errors.lastNameKana?.types?.required && (
                <span className="text-red-600 absolute bottom-0">
                  この項目は必須です。
                </span>
              )}
              {errors.lastNameKana?.types?.pattern && (
                <span className="text-red-600 absolute bottom-0">
                  カタカナで入力してください。
                </span>
              )}
            </div>
            <div className="flex items-center">
              <RequiredFormLabel
                title="名 (カナ)"
                className="ml-28 w-28 min-w-[100px] text-sm font-semibold text-lightGreen-200"
              />
              <input
                {...register("firstNameKana", {
                  required: true,
                  pattern: /^[ァ-ヴ][ァ-ヴー・]+$/i,
                })}
                className={formStyle}
                disabled={isPreviewVisible}
                value={watch("firstNameKana")}
              />
              {errors.firstNameKana?.types?.required && (
                <span className="text-red-600 ml-28 absolute bottom-0">
                  この項目は必須です。
                </span>
              )}
              {errors.firstNameKana?.types?.pattern && (
                <span className="text-red-600 ml-28 absolute bottom-0">
                  カタカナで入力してください。
                </span>
              )}
            </div>
          </div>
          <div className="py-5 border-t items-center flex relative">
            <RequiredFormLabel
              title="E-mail"
              className="w-28 min-w-[100px]	text-sm font-semibold text-lightGreen-200"
            />
            <input
              {...register("emailAddress", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i,
              })}
              className={formStyle}
              disabled={isPreviewVisible}
              value={watch("emailAddress")}
            />
            {errors.emailAddress?.types?.required && (
              <span className="text-red-600 absolute bottom-0 left-0">
                この項目は必須です。
              </span>
            )}
            {errors.emailAddress?.types?.pattern && (
              <span className="text-red-600 absolute bottom-0 left-0">
                不正な形式のメールアドレスです。
              </span>
            )}
          </div>
          <div className="pt-10	pb-20 text-center border-t">
            {!isPreviewVisible ? (
              <>
                <button
                  type="submit"
                  onClick={() => navigate(`/merchants/detail/${companyId}`)}
                  className="inline-block w-36 h-13 border rounded-lg text-sm border-lightGreen-200 text-lightGreen-200"
                >
                  戻る
                </button>
                <ConfirmationButton />
              </>
            ) : (
              <>
                <ReturnFormButton returnEvent={hidePreview} />
                <RegistrationButton registrationEvent={createEmployee}>
                  登録
                </RegistrationButton>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};
