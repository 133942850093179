import { AxiosResponse } from "axios";

import { api } from "./axiosConfig";
import {
  PasswordChangeRequestType,
  UserLoginResponseType,
  UserRequestType,
} from "../types/api/user";

// ログイン情報の有効の有無を確認する関数
export const PostAuthCheck = () => {
  return api
    .post<UserLoginResponseType>("admin/auth/check")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ログイン情報を送る
export const PostAuthLogin = (data: UserRequestType) => {
  return api
    .post("admin/auth/login", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// ログアウトするための関数
export const PostAuthLogout = () => {
  return api
    .post("admin/auth/logout")
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

// パスワードを変更するための関数
export const PostPasswordChange = (data: PasswordChangeRequestType) => {
  return api
    .post("/admin/auth", data)
    .then((res: AxiosResponse) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
