import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumbs } from "./BreadCrumbs";
import Circle from "../../images/circle.png";
import { useAuth } from "../../hooks/useAuth";

export const Header = () => {
  const { logout } = useAuth();
  const onClickHandler = () => {
    logout();
  };

  return (
    <>
      <header className="bg-lightGreen-100 sticky top-0 h-24 position flex w-4/5">
        <div className="flex justify-center items-center">
          <Breadcrumbs />
        </div>
        <ul className="flex items-center ml-auto">
          <li className="group relative ml-5">
            <Link className={"bg-lightGreen-300"} to="">
              <img
                className="hover:opacity-40"
                src={Circle}
                alt="circle.png"
                width={45}
                height={45}
              />
            </Link>
            <ul className="bg-white absolute top-11 right-0 hidden group-hover:block text-center rounded-lg p-4">
              <li className="group-hover:block w-36">
                <Link
                  to={"/password/change"}
                  className="text-lightGreen-300 text-xs border-b border-lightGreen-300"
                >
                  パスワード変更
                </Link>
              </li>
              <li className="group-hover:block">
                <button
                  onClick={onClickHandler}
                  className="text-lightGreen-300 text-xs border-b border-lightGreen-300"
                >
                  ログアウト
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </header>
    </>
  );
};
