import { useCallback, useState } from "react";

import {
  MerchantManagementUserRequest,
  MerchantRequestType,
  MerchantType,
} from "../../types/api/merchant";
import {
  GetMerchant,
  PostMerchant,
  PostMerchantUser,
  PutMerchant,
} from "../../api/apiMerchant";

export const useMerchant = () => {
  const [merchant, setMerchant] = useState<MerchantType>({
    id: 0,
    name: "",
    url: "",
    address: "",
    business_content: "",
  });

  const [isComplete, setIsComplete] = useState<boolean>(false);

  const saveMerchant = useCallback(async (data: MerchantRequestType) => {
    const res = await PostMerchant(data);
    if (res.status !== 201) {
      return alert("登録に失敗しました");
    }
    setIsComplete(true);
  }, []);

  const saveMerchantManagementUser = useCallback(
    async (data: MerchantManagementUserRequest) => {
      const res = await PostMerchantUser(data);
      if (res.status !== 201) {
        return alert("担当者登録に失敗しました");
      }
      setIsComplete(true);
    },
    []
  );

  const fetchMerchant = useCallback(
    async (
      companyId: string | undefined,
      setLoading: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
      const res = await GetMerchant(companyId);
      if (res.status !== 200) {
        return alert("利用会社詳細データの取得に失敗しました");
      }
      setMerchant(res.data.data);
      setLoading(false);
    },
    []
  );

  const updateMerchant = useCallback(
    async (data: MerchantRequestType, companyId: string | undefined) => {
      const res = await PutMerchant(companyId, data);
      if (res.status !== 200) {
        return alert("利用会社の編集に失敗しました");
      }
      setIsComplete(true);
    },
    []
  );

  return {
    saveMerchant,
    fetchMerchant,
    merchant,
    updateMerchant,
    saveMerchantManagementUser,
    isComplete,
  };
};
