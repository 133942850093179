import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { PostAuthCheck } from "../api/apiAuth";
import { useLoadingCheck } from "../hooks/useLoadingCheck";
import { UserLoginResponseType } from "../types/api/user";

export interface LoginUserContextType {
  loginUser: UserLoginResponseType | null;
  setLoginUser: Dispatch<SetStateAction<UserLoginResponseType | null>>;
  setLoading: Dispatch<SetStateAction<true | false>>;
}

export const LoginUserContext = createContext<LoginUserContextType>(
  {} as LoginUserContextType
);

export const LoginUserProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [loginUser, setLoginUser] = useState<UserLoginResponseType | null>(
    null
  );
  const { setLoading } = useLoadingCheck();

  const authCheck = async () => {
    setLoading(true);
    const res = await PostAuthCheck();
    if (res.status !== 200) {
      setLoading(false);
      return setLoginUser(null);
    }

    setLoading(false);
    return setLoginUser(res.data);
  };
  const values = {
    loginUser,
    setLoginUser,
    setLoading,
  };

  useEffect(() => {
    authCheck();
  }, []);

  {
    /********************************************************
     * TODO: apiと通信中はローディング画面を表示させる
     *******************************************************/
  }
  return (
    <LoginUserContext.Provider value={values}>
      {children}
    </LoginUserContext.Provider>
  );
};
