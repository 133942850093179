import React, { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const StressCheckReturnButton: FC = () => {
  const navigate = useNavigate();
  const currentUrl = useLocation();
  const companyId = currentUrl.pathname.charAt(18);

  return (
    <button
      type="submit"
      onClick={() => navigate(`../detail/${companyId}/stress_check_list`)}
      className="inline-block w-36 h-13 border rounded-lg border-lightGreen-200"
    >
      <span className="text-lightGreen-200 text-sm">戻る</span>
    </button>
  );
};
