import React, { memo } from "react";
import { useFetchMerchantList } from "../../../hooks/merchant/useFetchMerchantList";
import { LoadingTemplate } from "../../templates/LoadingTemplate";

import { MerchantList } from "../../templates/merchants/MerchantList";

export const Merchants = memo(() => {
  const { merchantList, isLoading } = useFetchMerchantList();

  return (
    <>
      {isLoading ? (
        <LoadingTemplate />
      ) : (
        <MerchantList merchantList={merchantList} />
      )}
    </>
  );
});
