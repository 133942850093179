import React, { memo, FC, useState, ChangeEvent } from "react";

import Logo from "../../../images/logo.png";
import { useAuth } from "../../../hooks/useAuth";
import { LoginButton } from "../../atoms/login/LoginButton";

export const Login: FC = memo(() => {
  const { login } = useAuth();

  const [userId, setUserId] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const onChangeUserId = (e: ChangeEvent<HTMLInputElement>) =>
    setUserId(e.target.value);
  const onChangeUserPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setUserPassword(e.target.value);

  const onClickLogin = () => login(userId, userPassword);

  return (
    <div className="min-h-screen bg-lightGreen-100 flex flex-col justify-center items-center sm:py-12">
      <div className="flex flex-col space-y-16 items-center  px-40 pt-20 pb-28 bg-white shadow rounded-lg">
        <div className="flex justify-center items-center">
          <img src={Logo} alt="logo.png" width={300} height={200} />
        </div>
        <div className="flex flex-col space-y-5 items-center justify-end w-full h-48">
          <label className="text-lg font-w6 leading-none text-gray-700 w-full">
            ログインアカウント
          </label>
          <input
            className="px-3 py-2 mt-1 mb-5 text-sm w-full border rounded-lg border-gray-700"
            value={userId}
            onChange={onChangeUserId}
          />
          <label className="text-lg font-w6 leading-none text-gray-700 w-full">
            パスワード
          </label>
          <input
            className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full border-gray-700"
            value={userPassword}
            type="password"
            onChange={onChangeUserPassword}
          />
        </div>
        <LoginButton onClick={onClickLogin}>ログイン</LoginButton>
        {/********************************************************
         * TODO: 4月リリース時点では一旦パスワード再設定画面を作らない
         *******************************************************/}
        {/* <a className="text-lg underline text-gray-700 mb-10" href="#">
          パスワードを忘れた方はこちら
        </a> */}
      </div>
      <span className="text-xs leading-3 text-center text-gray-600 mt-6">
        © 2022 CAPS株式会社
      </span>
    </div>
  );
});
