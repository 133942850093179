import React from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";

import { useDate } from "../../../hooks/useDate";
import Choice from "../../../images/choice.png";
import { StressCheckFormType } from "../../../types/api/stressCheck";

type InputProps = {
  formSelectStyle: string;
  register: UseFormRegister<StressCheckFormType>;
  isPreviewVisible: boolean;
  watch: UseFormWatch<StressCheckFormType>;
  defaultValueColumns: any;
};
export const StressCheckCreateStartDateForm = ({
  register,
  isPreviewVisible,
  formSelectStyle,
  watch,
  defaultValueColumns,
}: InputProps) => {
  const { setYear, setMonth, setDay } = useDate();

  const normalDateFormStyle =
    "ml-5 border rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none relative";
  const checkDateFormStyle =
    "ml-5 rounded-lg text-sm border-lightGreen-200 leading-tight focus:outline-none disabled:bg-white";

  const formStyle = !isPreviewVisible
    ? normalDateFormStyle
    : checkDateFormStyle;

  return (
    <div className="flex relative leading-relaxed content-center">
      <div className={formSelectStyle}>
        <select
          className="appearance-none rounded-lg w-28 h-12 pl-4
          focus:outline-none disabled:opacity-100"
          id="year"
          disabled={isPreviewVisible}
          defaultValue={watch(defaultValueColumns[0])}
          {...register(defaultValueColumns[0])}
        >
          {setYear().map((year) => (
            <option key={year} value={String(year)}>
              {`${year}年`}
            </option>
          ))}
        </select>
        {!isPreviewVisible && (
          <img
            className="w-3 h-2.5 absolute top-0 right-0 mr-2 mt-5"
            src={Choice}
          ></img>
        )}
      </div>
      <div className={formStyle}>
        <select
          className="appearance-none rounded-lg w-20 h-12 pl-4
        focus:outline-none disabled:opacity-100"
          id="month"
          disabled={isPreviewVisible}
          defaultValue={watch(defaultValueColumns[1])}
          {...register(defaultValueColumns[1])}
        >
          {setMonth().map((month) => (
            <option key={month} value={String(month)}>
              {`${month}月`}
            </option>
          ))}
        </select>
        {!isPreviewVisible && (
          <img
            className="w-3 h-2.5 absolute top-0 right-0 mr-2 mt-5"
            src={Choice}
          ></img>
        )}
      </div>
      <div className={formStyle}>
        <select
          className="appearance-none rounded-lg w-20 h-12 pl-4
          focus:outline-none disabled:opacity-100"
          id="day"
          disabled={isPreviewVisible}
          defaultValue={watch(defaultValueColumns[2])}
          {...register(defaultValueColumns[2])}
        >
          {setDay().map((day) => (
            <option key={day} value={String(day)}>
              {`${day}日`}
            </option>
          ))}
        </select>
        {!isPreviewVisible && (
          <img
            className="w-3 h-2.5 absolute top-0 right-0 mr-2 mt-5"
            src={Choice}
          ></img>
        )}
      </div>
    </div>
  );
};
