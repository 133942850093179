import React from "react";

export const Footer = () => {
  return (
    <>
      <div className="flex justify-end items-center h-24">
        <span className="text-lightGreen-300">@2022 CAPS株式会社</span>
      </div>
    </>
  );
};
